import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';
import { Row } from 'react-bootstrap';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';

const ExiliumIndexPage: React.FC = () => {
  return (
    <DashboardLayout className={'home-page exilium-page'} game="exilium">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Girls' Frontline 2: Exilium wiki and tier list</h1>
        <h2>
          Prydwen.gg is a wiki for Girls' Frontline 2: Exilium. Check our
          guides, tier lists and reviews for characters available in the game.
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Work in progress" />
      <div className="banner countdown">
        <div className="cta">
          <h4>
            Our Girls' Frontline 2: Exilium Database is currently being created!
          </h4>
          <p>
            Over the next few weeks expect the database to be updated daily with
            new content.
          </p>
        </div>
      </div>
      <SectionHeader title="Guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/gfl-exilium/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/exilium/categories/category_intro.jpg"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Characters"
          link="/gfl-exilium/characters"
          image={
            <StaticImage
              src="../../images/exilium/categories/category_characters.jpg"
              alt="Characters"
            />
          }
          isNew
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default ExiliumIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Girls' Frontline 2: Exilium Wiki & Database | Prydwen Institute"
    description="Prydwen.gg is a wiki for Girls' Frontline 2: Exilium. Check our guides, tier lists and reviews for characters available in the game."
    game="exilium"
  />
);
